






import {Component, Vue} from 'vue-property-decorator';
import {getFullDate, momentUTC} from '@/utilities';

@Component
export default class ClaimDetailsAcceptDate extends Vue {
  public get getAcceptDate() {
    return {
      date: getFullDate(this.getUntilDate),
      day: this.getDaysLeft >= 0 ? this.getDaysLeft : 0,
    };
  }

  public get getDaysLeft() {
    return momentUTC(this.getUntilDate).diff(momentUTC(new Date()), 'days') + 1;
  }

  private get getUntilDate() {
    return this.$store.getters.getClaim.untilDate;
  }
}
