












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ClaimAcceptModal extends Vue {
  @Prop({type: String, required: true})
  private amount!: string;
}
